import React, { Component, Fragment } from 'react'
import Navbar from '../common/Navbar'
import Footer from '../common/Footer'
import { earthidealAPIURL, assuredAPIURL } from '../../constants'
import {ApiService} from "../../services"
import ajax from 'superagent';
import jwtDecode from 'jwt-decode';
import StateSelector from '../SandboxMap/StateMap/StateSelector'
import _ from 'lodash';

/*import {
  ApiService,
  SharesDetailsService
} from '../../services'*/

import Event from '../Event'


export default class WelcomeToHaulcheck extends Component {

   constructor(props){
      super(props);
      const userJWT = localStorage.getItem('token');
      const userDecoded = jwtDecode(userJWT);

      let username = userDecoded['cognito:username'];
      let email = userDecoded.email;

      this.state = {
      	username, email,
      	eventtype: "haulCheckWelcomePage",
      	eventSeen: null, userDecoded,
      	stateSelection: []
      }
  	}

  	componentDidMount = () => {
  		let {eventtype, email} = this.state;

       ajax.get(`${earthidealAPIURL}/events/getByEventTypeAndUsername/${eventtype}/${email}`)
        .end((error, response) => {
          if (!error && response) {
          	if (response.body.eventSeen){
              	window.location.href = "/my-sites"
          	} else {
          		//this.markAsSeen();
          	}
          	this.setState({eventSeen: response.body.eventSeen});
          } else {
              console.log('There was an error fetching', error);
          }
        }
      );             
  	}

  	markAsSeen = async () => {
  		let {eventtype, email} = this.state;
  		ajax.post(`${earthidealAPIURL}/events/markAsSeen`)
		.send({
			eventtype,
			email
		})
		.end((error, response) => {
			if (!error && response) {
			    this.setState({seen: true}, () => window.location.href = "/my-sites")
			} else {
			    console.log('There was an error fetching', error);
			}
		});
  	}

  	handleStates = async (stateSelection) => {
			localStorage.setItem("defaultStates", stateSelection.toString());
			this.setState({stateSelection})
			await ApiService.setUserStates(stateSelection)
  	}

  	render = () => {
  		let {eventSeen} = this.state;
	  	return(<>
	  		<Navbar />


	  		{eventSeen === false ? 
	  		<main class="main-content">
	          <div class="section pt-0">
	            <div class="container">
	              <div class="row">

	                <div class="col-lg-5 mx-auto">
	                  <img src="../img/thumb/welcome.png" class="mt-6" />
	                  <h1 class="text-center mt-6">Welcome to Haul Check!</h1>
	                </div>


	                  <div class="col-lg-10 mx-auto mb-3 fs-20">

	                    <ul class="ml-6 fs-18 list-unstyled text-center">
	                      <li>Setup is quick and easy! Just <strong>add your site</strong> and <strong>enter your needs</strong> to get started.</li>
	                      {/*<li>We'll show you if you have any <strong>matches</strong> before you decide to <strong>Search or Post Externally.</strong></li>
	                      <li>Be sure to use the <strong>Chat Toolbox</strong> to unlock data for your matches and find the best haul!</li>*/}
	                    </ul>

	                  </div>

	                  <div class="col-lg-8 col mx-auto mb-5">
	                  	<div class="col-lg-5 mx-auto">
			                  <StateSelector
													onChange={this.handleStates}
												/>
											</div>
										</div>

	                  <div class="col-lg-5 gap-x mx-auto mb-6 flexbox">
	                      <a class="btn btn-block btn-outline-secondary mt-2" href="https://www.haulcheck.com/help-center/quick-start-guide/index.html" target="_blank">Quick Start Guide</a>
	                      <button 
	                      	class="btn btn-block btn-primary" 
	                      	onClick={this.markAsSeen} 
	                      	disabled={_.isEmpty(this.state.stateSelection)}>
	                      	Let's Go!
                      	</button>
	                    </div>

	                  <div class="col-lg-12 text-center">
	                    <small>Access our simple step-by-step Quick Start Guide from the Support menu at any time.</small>
	                  </div>

	              </div>
	            </div>
	        </div>
	      </main> : <div class="my-sites-scroll"><div className="loader">Loading...</div></div>}






	  		<Footer />
		</>)
	}

}
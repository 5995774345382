import React, {Component, Fragment}  from 'react';
import Navbar from '../common/Navbar';
import Footer from '../common/Footer';
import {url, earthidealAPIURL} from '../../constants';
import ajax from 'superagent';
import jwtDecode from 'jwt-decode';
import SandboxMap from '../SandboxMap/SandboxWrapper';
import Sidebar from './Sidebar'
import InHouse from '../InHouse'
//import Conversations from '../Conversations';
import Event from '../Event'
import SiteTeamModal from '../common/SiteTeam/Modal'
import ModalShareDetails from '../Conversations/ModalShareDetails';
import MobileDisplaySwitcher from '../common/MobileDisplaySwitcher'

import {
  isMobile,
  isBrowser
} from 'react-device-detect'

import {CSSTransition} from 'react-transition-group'

import {
  //ApiService,
  HaulCheckService,
  PMGroupsService,
  //MatchesService,
  //ConversationsService
} from '../../services'

import MaterialInfo from "./SidePanel"

import _ from 'lodash'

import {getDerivedDisplayStateFromURL} from './useMySitesPanelChange'

import './animation.css'
import './styles.css'

export default class MySites extends Component {

   constructor(props){
      super(props);
      const userJWT = localStorage.getItem('token');
      const userDecoded = jwtDecode(userJWT);

      let username = userDecoded['cognito:username'];
      let email = userDecoded.email;

      this.state = {
          username,
          userDecoded,
          email,

          allSiteResourcesList: {fullList: []},
          groupSiteResourcesList: {fullList: []},

          materialModeMaterialList: [],

          mapLocked: false,
          sharedSite: null,

          displayState: /*(isMobile ? "sidebar" : "sandbox")*/ null,
          displayStateHistory: [],

          openSites: [],
          activeSite: 0,

          panelProps: {
            title: {name: "", resource: "", type: "", quantity: "", sharedRoutes: null},
            idsite: null,
            idsiteresource: null,
            pmGroupName: "",
            matches: [],
            activeSite: null,
            sharedSites: [],
            activeSite: {geom: {x: 0, y: 0}},
            expiration_date: null,
            idmatchsiteresource: 0,
            siteshared: 0,
            infoshared: 0,
            groupsListMode: false,
            materialInfo: false
          },

      }
  }

  componentWillMount = async () => {

    let {email, panelProps} = this.state;

    let matchesPanelKey = JSON.parse(localStorage.getItem("matchesPanelKey"));

    if (matchesPanelKey != null && matchesPanelKey != undefined && matchesPanelKey.idsite != undefined && matchesPanelKey.idsite_resource != undefined){
      console.log("getSiteList triggering matchesPanelKey panel switch");
      localStorage.removeItem("matchesPanelKey");
      this.switchSidePanel("inHouse", matchesPanelKey);
    }

    let pmGroup = await PMGroupsService.getPmGroupByEmail(email, "mySites/componentWillMount");
 
    panelProps.pmGroupName = (pmGroup ? pmGroup.name : "My Imbalances")
    panelProps.idpmgroup = (pmGroup ? pmGroup.idpmgroup : 0);
   
    this.setState({
      panelProps 
    });

  }

  switchSiteResource = async (idsite, idsiteresource, groupsListMode) => {
    let {panelProps} = this.state;
    panelProps.idsite = idsite;
    panelProps.idsiteresource = idsiteresource;
    this.setState({panelProps})
  }

  setMapLocked = (val) => {
    this.setState({mapLocked: val});
  }

  setSiteRefresh = (val) => {
    this.setState({siteRefresh: val});
  }

  showSharedSite = (sharedSite) => {
    let {displayState} = this.state;

    //this.setState({sharedSite, displayState: "sandbox"})
    if (displayState != "sandbox"){
      this.setState({displayState: "sandbox"}, () => {
        setTimeout(()=> {
          this.setState({sharedSite});
        }, 2000);
        
      })
    } else {
      this.setState({sharedSite})
    }
  }

  showSiteRoutes = (material) => {
    console.log("mySites showSiteRoutes", "Calling showSiteRoutes with material", material);
    this.switchSidePanel("sandbox", true);

    /*fetch(`${earthidealAPIURL}/sites/getRoutesForMaterial/${material.idsite_resource}/${material.idsite}`)
      .then(res =>res.json())
      .then(results => {
        const  { routes, uniqueDestinations } = results

        console.log("Routes in showSiteRoutes:", routes);
        console.log("uniqueDestinations in showSiteRoutes:", uniqueDestinations);

        let {panelProps} = this.state;
        panelProps.sharedRoutes = routes;
        panelProps.originSite = material;
        this.setState({panelProps});
    })*/
  }

  componentDidUpdate = (prevProps) => {

    let defaultFrontPagePath = "/app/sites/my-sites"

    //console.log("1214", "MySites componentDidUpdate","prevProps.match.params:", prevProps.match.params);
    //console.log("1214", "MySites componentDidUpdate","this.props.match.params:", this.props.match.params);

    console.log("1214", "comparing updates with prev match", prevProps.match.params, "current match", this.props.match.params, "prev location", prevProps.location.pathname, "current location", this.props.location.pathname)

    if (prevProps.location.pathname != this.props.location.pathname || this.state.displayState === null){

      console.log("MySites componentDidUpdate", "this.props.match:", this.props.match);
      console.log("MySites componentDidUpdate", "this.props.location:", this.props.location);

      let newState = getDerivedDisplayStateFromURL(this.props.location, this.state.displayState);


      this.setState(newState, () => {
        //if (openMessageThread){
          
          //this.props.history.goBack();
        //}
      })
    }
  }


  openMessages = () => {
    let newState = {};
    newState.panelProps = JSON.parse(localStorage.getItem('matchesPanel'));
    this.setState(newState, () => {
      document.getElementById('open-message-thread').click();
    });
  }

  switchSidePanel = (displayState, params) => {
    console.log("Calling switchSidePanel with:", displayState, params)

    if (displayState == "messages") return;
    else if (displayState == "sites" || displayState == "sidebar" || displayState == "sandbox") this.props.history.push("/app/sites");
    else if (displayState == "inHouse") {
      localStorage.setItem('matchesPanel', JSON.stringify(params))
      this.props.history.push("/app/matches")
    } else if (displayState == "materialInfo") { 
      this.props.history.push(this.props.history.location.pathname + "/materialInfo")
    }
  }


  /*switchSidePanel = (displayState, params Wildcard obj that can have all manner of different parameters depending on the state being switched to) => {

    let {displayStateHistory, panelProps} = this.state;
    let openMessageThread = false;
    let openSharedDetails = false;

    if (displayState == "previous"){
      displayState = displayStateHistory.pop();
    } else if (displayState == "conversations"){
      //Don't do anything to the display state history 
    } else {
      displayStateHistory.push(this.state.displayState);
    }

    if ((displayState == "matchsInbox" || displayState == "inHouse") && params != undefined){
      this.setState({displayState: ""});
      this.switchSiteResource(params.idsite, params.idsiteresource, params.groupsListMode);
      panelProps.groupsListMode = params.groupsListMode;
    } else if (displayState == "conversations" && params != undefined){
      panelProps.idmatchsiteresource = params.idmatchsiteresource;
      panelProps.groupsListMode = params.groupsListMode;
      displayState = this.state.displayState;
      openMessageThread = true;
    } else if (displayState == "sharedDetails"){
      console.log("Calling switchSidePanel with sharedDetails")
      panelProps.idsite_resource = params.idsite_resource;
      panelProps.groupsListMode = params.groupsListMode;
      displayState = this.state.displayState;
      openSharedDetails = true;

      if (panelProps.reloadSharedDetails){
        panelProps.reloadSharedDetails = false;
      } else {
        panelProps.reloadSharedDetails = true;
      }

    } else if (displayState == "sandbox"){
      if (!params){
        panelProps.sharedRoutes = null; 
        panelProps.originSite = null;
      }
    }

    this.setState({displayState, displayStateHistory, panelProps}, () => {
      if (openMessageThread){
        document.getElementById('open-message-thread').click();
      } else if (openSharedDetails){
        document.getElementById("open-details-form-"+params.idsite_resource+"-mySites").click();
      }
    });
  }*/

  openSiteTeamModal = (idsite) => {
    let {panelProps} = this.state;
    panelProps.idsite = idsite;

    this.setState({panelProps}, () => {
      document.getElementById('open-edit-site-team-modal').click();
    });
  }

  shouldDisplaySidebar = () => {
    if (isBrowser){
      return (this.state.displayState == "sandbox");
    } else if (isMobile){
      return (this.state.displayState == "sidebar");
    }
  }

  renderPanelWrapper = (children) => {
    if (isBrowser){
      return <main className="main-content">
        <section className="section py-0 shadow-2">
          <div className="row no-gutters">
            {children}
          </div>
        </section>
      </main>
    } else if (isMobile){
      return <main className="main-content">
        {children}
      </main>
    }
  }

  render(){

    console.log("isMobile:", isMobile);

    let {siteRefresh, sitesList, displayState, panelProps} = this.state;
    //let isMobile = true; //For debugging
    //let isBrowser = false; //For debugging

    return ( <>

      <Navbar />

      <MaterialInfo
        {...panelProps}
        onClose={() => {
          console.log("Calling MaterialInfo onClose with panelProps", panelProps)
          let newPanelProps = _.cloneDeep(panelProps);
          newPanelProps.showMaterialInfo = false;
          this.setState({panelProps: newPanelProps});
        }}
      />

      {/*displayState != "sandbox" ? 
        <nav class="nav navbar-dark border-bottom">
          <a class="nav-link ml-0 mb-0 py-0" href="#" onClick={() => this.switchSidePanel("sandbox")}>><i class="fa fa-angle-left m-2" aria-hidden="true"></i>Sites</a>
        </nav>
      : null*/}

      {isMobile && (displayState == "sidebar" || displayState == "sandbox") ? <MobileDisplaySwitcher
        changeDisplayState={this.props.history.push}
        buttons={[
          {
            icon: "ti ti-menu-alt",
            displayState: "sidebar",
            label: "",
            isActive: (displayState == "sidebar"),
          },
          {
            icon: "ti ti-map-alt",
            displayState: "sandbox",
            label: "",
            isActive: (displayState == "sandbox"),
          }

        ]}

      /> : null}


      {this.renderPanelWrapper(<>
        {this.shouldDisplaySidebar() ? 
          this.renderSidebar() :
        null}

        {this.renderEmptyState()}
        {this.renderSandbox()}
        {this.renderAnimatedSidePanel("inHouse", this.renderInHouse, "right")}
        {this.renderAnimatedSidePanel("", this.renderLoading, "right")}
        {this.renderAnimatedSidePanel("materialInfo", this.renderMaterialInfo, "left")}
        {/*this.renderAnimatedSidePanel("conversations", this.renderConversations)*/}

      </>)}

      <Event eventtype="phoneNumberCompanyName" />

      {/*<Conversations 
        originsite={panelProps.idsite}
        idmatchsiteresource={panelProps.idmatchsiteresource}
        switchSidePanel={this.switchSidePanel}
        groupsListMode={panelProps.groupsListMode}
        updateMatches={() => {return true}}
      /> */}

      <SiteTeamModal idsite={panelProps.idsite} />

      <ModalShareDetails idpmgroup={panelProps.idpmgroup} idsite_resource={panelProps.idsite_resource} parent="mySites" forceReload={panelProps.reloadSharedDetails} />

      {(isMobile && displayState == "sandbox") ? null : <Footer />}
    </>)
  }

  renderSidebar = () => {
    return <Sidebar
      switchSidePanel={this.switchSidePanel}
      mapLocked={this.state.mapLocked}
      setMapLocked={this.setMapLocked}
      showSiteRoutes={this.showSiteRoutes}
      openSiteTeamModal={this.openSiteTeamModal}
      getSiteInfo={this.getSiteInfo}
      isMobile={isMobile}
      isBrowser={isBrowser}
      setActiveSiteParent={(activeSite) => this.setState({activeSite})}
      forceTab={this.state.panelProps.forceTab}
    />
  }

  renderAnimatedSidePanel = (mode, renderFunc, direction="right") => {
    let {displayState, panelProps} = this.state;

    let showPanel = ((mode == this.state.displayState) || (this.state.panelProps[mode] == true));
    
    console.log("Calling renderAnimatedSidePanel with mode " + mode +"; showPanel:", showPanel);
    //let isMobile = true; //For debugging
    //let isBrowser = false; //For debugging


    return(<Fragment>

      {showPanel && isBrowser ?
        mode == "inHouse" ?
          renderFunc()
        :
          <div className="col-md-6 col-lg-8 bg-light my-sites-scroll">
            <div class="col-12 pt-6 px-lg-6 mx-auto">
              {renderFunc()}
            </div>
          </div> 
      : null }

      <CSSTransition 
        in={showPanel && isMobile}
        unmountOnExit
        timeout={400}
        classNames={`slide-${direction}`}
        className={`slide-${direction}-base`}
      >

        <div style={{zIndex: 1000, position: 'absolute', width: '100%'}} id="matches-inbox">
            {renderFunc()}
        </div>

      </CSSTransition> 

    </Fragment>)

  }

  renderMaterialInfo = () => {
    let {panelProps} = this.state;

    return (
      <>Test?</>
    )

  };

  renderInHouse = () => {
    console.log("Calling renderInHouse");
    let {panelProps} = this.state;
    //let isMobile = true; //For debugging
    //let isBrowser = false; //For debugging
    return (
      <InHouse 
        switchSidePanel={this.switchSidePanel}
        idsite={panelProps.idsite}
        idsite_resource = {panelProps.idsiteresource}
        groupsListMode={panelProps.groupsListMode}
        history={this.props.history}
        location={this.props.location}
        match={this.props.match}
        isMobile={isMobile}
        isBrowser={isBrowser}
        openMessages={this.openMessages}
      />
    )
  }

  renderLoading = () => {
    return "Loading...";
  }

  renderEmptyState = () => {
    if (this.state.displayState === null) return <div className="col-md-12 my-sites-scroll"></div>;
    else return null;
  }

  renderSandbox = () => {
    let {mapLocked, sharedSite, displayState, panelProps, activeSite} = this.state;

    if (displayState !== "sandbox") return null;

    if (isMobile){
      return <>
        <SandboxMap 
          locked={mapLocked} 
          sharedSite={sharedSite}
          setMapLocked={this.setMapLocked}
          showSearchBar={true}
          idpmgroup={panelProps.idpmgroup}
          displaySites={true}
          activeSite={activeSite}
          isBrowser={isBrowser}
          isMobile={isMobile}
        />
      </>
    } else if (isBrowser) {
      return(<>
        <div className="col-md-6 col-lg-8 my-sites-scroll sandbox">
          <SandboxMap 
            locked={mapLocked} 
            sharedSite={sharedSite}
            setMapLocked={this.setMapLocked}
            showSearchBar={true}
            idpmgroup={panelProps.idpmgroup}
            displaySites={true}
            isBrowser={isBrowser}
            isMobile={isMobile}
            activeSite={activeSite}
          />
        </div>
      </>);
    }
  }

}

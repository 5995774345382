import React, {Component, Fragment} from 'react';
import ajax from 'superagent';
import _ from 'lodash';
import jwtDecode from 'jwt-decode';
import {
  ApiService,
  PMGroupsService,
  SiteTeamsService } from '../../../services';
import TextField from '@material-ui/core/TextField';
import Autocomplete from '@material-ui/lab/Autocomplete';
import { url, earthidealAPIURL } from '../../../constants'
import SuccessPopup from '../Popups'


export default class SiteTeamModal extends Component{

    constructor(props){
        super(props);
        
        let idsite = 0;

        let defaultInviteObj = {email: "", role: "Estimator", invalidEmail: false, willBeRemoved: false, is_site_manager: false}
        const userJWT = localStorage.getItem('token');
        const userDecoded = jwtDecode(userJWT);

        this.state = {
            users: [],
            invitedEmails: [JSON.parse(JSON.stringify(defaultInviteObj))],
            profilesList: [],
            usersList: [],
            ownerRole: "Estimator",
            ownerIsManager: false,
            defaultInviteObj,
            invitedEmailsChanged: false,
            site: {
                name: "",
                idsite: 0
            },
    		invitingUserEmail: userDecoded['email'], 
            username: userDecoded['cognito:username'],
            waiting: false,
            positions: ["Estimator", "Project Manager", "Foreman", "Field Engineer"],
            canEditUsers: false,
            startingTeam: true,
            removingManager: false,
            needMoreMembers: false,
            group: [],
            groupParams: [],

            popupTriggerUsersAdded: false,
            popupTriggerUsersRemoved: false,
            popupTriggerUsersUpdated: false,
            popupTriggerManagerChanged: false,
        }
    }

    componentWillMount = () => {
        if (this.props.idsite != 0 && this.props.idsite != null){
            console.log("siteteam1 loading from componentWillMount with idsite " + this.props.idsite)
            this.loadSite(this.props.idsite);
        }
    }

    componentWillReceiveProps = async (newProps) =>{
        //console.log("siteteam newProps idsite", newProps.idsite);
        //console.log("siteteam saved idsite", this.state.site.idsite);

        if (newProps.idsite != this.state.site.idsite && newProps.idsite != 0 && newProps.idsite != null){
            console.log("siteteam1 loading site with new idsite " + newProps.idsite + " and old idsite " + this.state.site.idsite);
            this.loadSite(newProps.idsite)
        }
    }

    loadSite = async (idsite) => {
        this.setState({ invitedEmails: [ JSON.parse(JSON.stringify(this.state.defaultInviteObj)) ]});
        let site = await ApiService.getAllSiteInfo(idsite);
        if (site.length > 0){
            site = site[0];
            //console.log("SiteTeam site:", site)
            this.setState({ site });
        }

        let usersList = await SiteTeamsService.getSiteTeamMembers(idsite);
        //console.log("SiteTeam users list:", usersList);
        this.setState({ usersList });

        if (usersList.length == 0 && site.username == this.state.username){
            this.setState({canEditUsers: true, startingTeam: true});

            let ownerIsManager = true;

            let esitmatorObject = JSON.parse(JSON.stringify(this.state.defaultInviteObj));

            let pmObject = JSON.parse(JSON.stringify(this.state.defaultInviteObj));
            pmObject.role = "Project Manager";

            let foremanObject = JSON.parse(JSON.stringify(this.state.defaultInviteObj));
            foremanObject.role = "Foreman";

            this.setState({
                invitedEmails: [
                    esitmatorObject,
                    pmObject,
                    foremanObject
                ], ownerIsManager
            }) 

            this.loadGroup([{email: this.state.invitingUserEmail}]);
        } else if (usersList.length == 0 && site.username != this.state.username) {

            fetch(`${earthidealAPIURL}/users/getUserInfo/${site.username}`)
                  .then(res =>res.json())
                  .then(results => {
                    let user = {
                        name: results.user,
                        username: this.state.username,
                        role: "",
                        is_site_manager: 1,
                        email: results.email,
                    }
                    this.setState({usersList: [user], canEditUsers: true, startingTeam: false}, () => {
                        this.loadGroup([user]);
                    });
                })

        } else {
            this.setState({startingTeam: false});

            /*for (var i = 0; i < usersList.length; i++){
                if (usersList[i].username == this.state.username){
                    if (usersList[i].is_site_manager || site.username == this.state.username){*/
                        this.setState({canEditUsers: true});
                   /* }
                }
            }*/

            this.loadGroup(usersList);
        }
    }

    loadGroup = async (users) => {
        if (users){
            this.setState({groupParams: users});
        } else {
            users = this.state.groupParams;
        }

        let {invitingUserEmail} = this.state;
        //console.log("SiteTeam initial group of users:", users);
        let usersEmail = users.map(({email}) => {return email});
        let returnedGroup = await PMGroupsService.getPmGroupMembersByEmail(invitingUserEmail);
        console.log("siteteam1 returnedGroup:", returnedGroup);
        //console.log("SiteTeam usersEmail:", usersEmail);

        let group = [];

        for (var i = 0; i < returnedGroup.length; i++){
            if (returnedGroup[i]){
                let index = usersEmail.indexOf(returnedGroup[i].email);
                let uindex = usersEmail.indexOf(returnedGroup[i].username);

                if (index == -1 && uindex == -1){
                    group.push(returnedGroup[i]);
                }
            }
        }

        this.setState({group});
    }

    toggleUserRemove = (user, index) =>{
        let usersList = this.state.usersList;
        usersList[index].willBeRemoved = !usersList[index].willBeRemoved;
        this.setState({usersList});
    }

    toggleUserIsManager = async (e, index) =>{
        e.preventDefault();
        await this.clearManager();
        let {usersList} = this.state;
        usersList[index].is_site_manager = !usersList[index].is_site_manager;
        this.setState({usersList});
    }

    toggleNewUserIsManager = async (e, index) =>{
        e.preventDefault();
        await this.clearManager();
        let {invitedEmails} = this.state;
        invitedEmails[index].is_site_manager = !invitedEmails[index].is_site_manager;
        this.setState({invitedEmails});
    }

    toggleOwnerIsManager = async (e) => {
        e.preventDefault();
        await this.clearManager();
        this.setState({ownerIsManager: true});
    }

    clearManager = async () => {
        let {usersList, invitedEmails} = this.state;

        for (var i = 0; i < usersList.length; i++){
            usersList[i].is_site_manager = false;
        }

        for (var i = 0; i < invitedEmails.length; i++){
            invitedEmails[i].is_site_manager = false;
        }

        this.setState({ownerIsManager: false, invitedEmails, usersList});
    }

    updateEmail = (key, value) => {
       let {invitedEmails} = this.state;
       invitedEmails[key].email = value.email;
       invitedEmails[key].name = value.name;
       this.setState({invitedEmails, invitedEmailsChanged: true});
    }

    updateProfile = (key, value) => {
       let {invitedEmails} = this.state;
       invitedEmails[key].role = value;
       this.setState({invitedEmails, invitedEmailsChanged: true});
    }

    addNewUserRow = () => {
        this.setState(state => {
            const invitedEmails = [...state.invitedEmails, JSON.parse(JSON.stringify(state.defaultInviteObj))];
            return {invitedEmails}
        })
    }

    verifyUsers = () => {
        //if no users are being added, just say all 0 of them are valid by default
        if (this.state.invitedEmailsChanged === false) return true;

        //Otherwise, check
        let validUsersList = [];
        let hasInvalidUsers = false;

        for (var i = 0; i < this.state.invitedEmails.length; i++){

            let userIsValid = this.verifyIndividualUser(i);
            if (userIsValid === true){
                validUsersList.push(this.state.invitedEmails[i].email);
            } else if (userIsValid === false) {
                hasInvalidUsers = true;
            }
        }

        if (hasInvalidUsers){
            this.setState({hasInvalidUsers: true});
            return false;
        } else { 
            this.setState({hasInvalidUsers: false});
            return validUsersList;
        }
    }

    verifyIndividualUser(i){
    	let {invitedEmails} = this.state;
        let email = invitedEmails[i].email;
        let validUser = true;

        let emailRegexp = /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?(?:\.[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?)*$/;
        let validEmail = emailRegexp.test(email);

        if (email != "" && !validEmail){
            validUser = false;
            invitedEmails[i].invalidEmail = true;
            this.setState({invitedEmails})
        } else {
        	invitedEmails[i].invalidEmail = false;
            this.setState({invitedEmails})
        }

        return validUser;
    }

    openPopup = (popupId) => {
        this.setState({[popupId]: true})
        
    }

    sendData = async () =>{
    	let {site, startingTeam, username, ownerRole, ownerIsManager, invitedEmails, invitingUserEmail, invitedEmailsChanged} = this.state;
        this.setState({waiting: true});

        if (startingTeam && !invitedEmailsChanged){
            this.setState({needMoreMembers: true});
            return;
        } else {
            this.setState({needMoreMembers: false});
        }
  
        let verifiedUsers = this.verifyUsers();
        if (verifiedUsers === false) return;

        
        if (startingTeam){
            invitedEmails= JSON.parse(JSON.stringify(invitedEmails));
            invitedEmails.unshift({
                email: invitingUserEmail,
                role: ownerRole,
                is_site_manager: ownerIsManager
            })
        }

        const data = {
            invitedEmails,
            invitingUserEmail,
            idsite: this.props.idsite
        };

        const removedEmails = []
        let existingUserManager = false;
        let removingManager = false;
        let usersList = this.state.usersList;
        for (var i = 0; i < usersList.length; i++){
            let {willBeRemoved, is_site_manager, username} = usersList[i];

            if (willBeRemoved && is_site_manager){
                removingManager = true;
            }

            else if (willBeRemoved && !is_site_manager){
                removedEmails.push(username);
            } 

            else if (is_site_manager && !willBeRemoved){
                existingUserManager = username;
            }
        }

        this.setState({removingManager});

        let popupTriggered = false;

        if (removingManager){
            this.setState({waiting: false});
            return;
        } else {

            data.removedEmails = removedEmails;

            if (removedEmails.length > 0){
                await SiteTeamsService.removeSiteTeamMembers(data);

                if (verifiedUsers.length > 0){
                    await SiteTeamsService.addSiteTeamMembers(data);
                    this.openPopup("popupTriggerUsersUpdated");   
                    popupTriggered = true;             
                } else {
                    this.openPopup("popupTriggerUsersRemoved");      
                    popupTriggered = true;
                }
            }

            else if (verifiedUsers.length > 0){
                await SiteTeamsService.addSiteTeamMembers(data);
                this.openPopup("popupTriggerUsersAdded");
                popupTriggered = true;
            } else {
                console.log("No changes made");
            }


            if (existingUserManager){
                await SiteTeamsService.setSiteManager({username: existingUserManager, idsite: data.idsite, invitingUserEmail});
                if (!popupTriggered){
                    this.openPopup("popupTriggerManagerChanged")
                }
            }

            this.setState({waiting: false});

            this.loadSite(this.state.site.idsite);

            if (this.props.callback){
                this.props.callback();
            }


            document.getElementById('close-the-edit-site-team-modal').click();
        }
    }


    render(){

        let {site, waiting, canEditUsers, startingTeam} = this.state;

        return(<React.Fragment>
            <button data-toggle="modal" data-target="#edit-site-team" id="open-edit-site-team-modal" style={{display:'none'}} onClick={() => this.loadGroup(false)}>Edit Site Team</button>

            <div id="edit-site-team" className="modal fade" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
                <div className="modal-dialog modal-lg" role="document">
                    <div className="modal-content">
                        <div className="alert alert-primary" role="alert">
                            <strong>Site Team</strong>
                            <button type="button" className="close" data-dismiss="modal" aria-label="Close" id="close-the-edit-site-team-modal">
                                <span aria-hidden="true">&times;</span>
                            </button>
                        </div>
                        <div className="modal-body pt-0">
                            <div className="row m-0">
                               <p className="lead strong ml-lg-3">{site.name}</p>
                                {startingTeam ? 
                                    this.renderMyPosition()
                                :   this.renderUsers()}
                                {canEditUsers ? <><div className="col-12 col-lg-11 mt-6 mx-lg-auto">
                                    <div className="mt-5 mb-3 ml-0">
                                        <label className="p strong">
                                            {startingTeam ? "Site Team" : "Add to Team"}
                                        </label>
                                    </div>
                                    <div className="form-group row gap-y">
                                        
                                            {this.renderAddUsers()}
                                            <button type="button" onClick={this.addNewUserRow} className="btn btn-outline-secondary mb-5 mb-lg-0 ml-lg-4 px-4"><i className="fa fa-plus fw-400"></i></button>
                                        
                                    </div>
                                </div></> : null}
                                {/*<hr className="col-11 mb-6" />
                                <h5 className="ml-lg-3">Disposal Partners</h5>
                                <div className="lead col-lg-11 mt-2 px-0 ml-lg-4">
                                    {this.renderDisposalPartners()}
                                </div>*/}
                            </div>
                        </div>

                        <p class="small mx-lg-6 mb-2">Set the Primary Contact to the person who needs to get email notifications about new matches.</p>

                        <p class="small mx-lg-6">Tip: If you’re an estimator who just won a bid, set the new project PM as the Primary Contact. Then, go to the Site menu and use ‘Update Site Phase’ to roll this site over to the Construction phase.</p>

                        {this.state.removingManager ? 
                            <span class="text-danger">Error: You're removing a user who is the Primary Contact. Please select a different user to take over that role.</span>
                        : null}

                        {this.state.needMoreMembers ? 
                            <span class="text-danger">Error: You need at least one other person to create a site team.</span>
                        : null}

                        <button type="button" className="btn btn-lg btn-primary" onClick={this.sendData} >
                            {waiting ?  <img src="../img/app/photoswipe/preloader.gif" /> : null}
                            {canEditUsers ? "Update Site Team" : "Close"}
                        </button>
                    </div>
                </div>
            </div>

            <SuccessPopup
                popupId="popupSiteTeamUsersAdded"
                title="Success!"
                message="Users added!"
                trigger={this.state.popupTriggerUsersAdded}
                callback={() => this.setState({popupTriggerUsersAdded: false})}
            />

            <SuccessPopup
                popupId="popupSiteTeamUsersRemoved"
                title="Success!"
                message="Users removed!"
                trigger={this.state.popupTriggerUsersRemoved}
                callback={() => this.setState({popupTriggerUsersRemoved: false})}
            />

            <SuccessPopup
                popupId="popupSiteTeamUsersUpdated"
                title="Success!"
                message="Users updated!"
                trigger={this.state.popupTriggerUsersUpdated}
                callback={() => this.setState({popupTriggerUsersUpdated: false})}
            />

            <SuccessPopup
                popupId="popupSiteTeamManagerUpdated"
                title="Success!"
                message="Primary Contact updated!"
                trigger={this.state.popupTriggerManagerUpdated}
                callback={() => this.setState({popupTriggerManagerUpdated: false})}
            />

      </React.Fragment>)

    }

    renderMyPosition = () => {
        let {ownerRole, ownerIsManager} = this.state;

        return(<Fragment>
            <div className="col-12 col-lg-11 mt-2">
                <div className="mb-3">
                    <label className="p strong">My position</label>
                </div>

                <div className="form-group row gap-y">

                    <div className="col-12 col-lg-9 pt-2 pl-6 pt-lg-0">
                        <select className="form-control" value={ownerRole} onChange={(e) => {
                            this.setState({ownerRole: e.target.value})
                        }}>
                          {this.renderOptions()}
                        </select>
                    </div>
                    <div className="col-lg-3 p-0 pl-lg-4">
                        <div className="custom-control custom-radio pt-4" onClick={this.toggleOwnerIsManager}>
                            <input className="custom-control-input" id="my-position-radio" type="radio" value={ownerIsManager} checked={ownerIsManager}  onClick={this.toggleOwnerIsManager} />
                            <label className="custom-control-label" for="my-position-radio">
                                { ownerIsManager ? "Primary Contact" : "Set as Primary" }
                            </label>
                        </div>
                    </div>
                </div>
            </div>
        </Fragment>)
    }

    renderUsers = () =>{
    	let {usersList} = this.state;

        return this.renderUserGroup(this.state.usersList)
    }

    renderUserGroup = (group) =>{
        let {canEditUsers, site} = this.state;

        return(
            group.map((item, index) => {
                let willBeRemoved = this.state.usersList[index].willBeRemoved;

                return(<Fragment key={index}>

                    <div className="col-12 px-0 clearfix mb-4">

                        <div className={"col-4 float-left pl-5" + (willBeRemoved ? ' text-danger' : '')}><strong>{item.role}</strong></div>
                        <div className={"col-3 small float-left pt-1" + (willBeRemoved ? ' text-danger' : '')}>{item.name ? item.name : item.username}</div>

                        {item.username != site.username && canEditUsers ?
                            <a className={"small item-remove text-dark float-right clearfix mt-1" + (willBeRemoved ? ' text-danger' : '')} href="#" onClick={() => this.toggleUserRemove(item, index)}><i className="ti-close"></i></a>
                        : null}
                       
                        <div className={"col-3 custom-control custom-radio float-right pt-2"} onClick={e => this.toggleUserIsManager(e, index)}>
                            <input className="custom-control-input" type="radio" id={"existing-group-"+index} disabled={!canEditUsers} value={true} checked={item.is_site_manager} onClick={e => this.toggleUserIsManager(e, index)} />
                            <label className={"custom-control-label fw-200"  + (willBeRemoved ? ' text-danger' : '')} for={"existing-group-"+index}> {item.is_site_manager ? "Primary Contact" : "Set as Primary"}</label>
                        </div>
                    </div>

                </Fragment>)

                /*return(
                    <div className="col-12 col-lg-10 px-0 mx-lg-auto clearfix mb-4" key={index}>
                        <div className="lead">

                            {willBeRemoved ? 
                                <i className="ti-minus text-danger mt-2 mr-2 float-left"></i>
                                 : <i className="ti-check text-success mt-2 mr-2 float-left"></i>
                            }
                            {item.name ? 
                                <React.Fragment>
                                    <strong className={willBeRemoved ? ' text-danger' : 'text-dark'}>{item.name}</strong> <span className={willBeRemoved ? ' text-danger' : 'text-dark'}> - {item.email}</span>
                                </React.Fragment>
                            : 
                                <strong className={willBeRemoved ? ' text-danger' : 'text-dark'}>{item.username}</strong>
                            }

                            {item.role != "Site Owner" && canEditUsers ?
                                <a className="small item-remove text-dark float-right clearfix mt-1" href="#"
                                onClick={() => this.toggleUserRemove(item, index)}>
                                    <i className={"ti-close" + (willBeRemoved ? ' text-danger' : '') }></i>
                                </a>
                            : null}

                        </div>
                         <div className={"small ml-5 float-left"+ (willBeRemoved ? ' text-danger' : '') }>{item.role}</div>
                    </div>
                )*/
            })
        )
    }

    renderAddUsers = () => {
        return this.state.invitedEmails.map((item, index) => {
            return this.renderAddUserRow(index)
        })
    }

    renderAddUserRow = (key) => {

        return(<React.Fragment key={key}>

            <div className="col-12 col-lg-4 pt-2 pl-6 pt-lg-0">
                <select className={"form-control" + (this.state.invitedEmails[key].invalidEmail ? " is-invalid" : "")}
                    value={this.state.invitedEmails[key].role || ""} 
                    onChange={e => { this.updateProfile(key, e.target.value); }}>
                    {this.renderOptions()}
                </select>
            </div>
            <div className="col-lg-5 p-0 pl-lg-4">
                <div className="">
                    {/*<input className={"form-control" + (this.state.invitedEmails[key].invalidEmail ? " is-invalid" : "")} 
                    type="email"  placeholder="name@company.com"
                    value={this.state.invitedEmails[key].email || ""} 
                    onChange={(e) => {
                    this.updateEmail(key, e.target.value)
                    }} />*/}

                    <Autocomplete
                        id="combo-box-user-group"
                        options={this.state.group}
                        getOptionLabel={(option) => {
                            if (option.name){
                                return option.name;
                            } else {
                                return option.email;
                            }
                        }}
                        inputValue={
                            (this.state.invitedEmails[key] ? 
                                (this.state.invitedEmails[key].name ? this.state.invitedEmails[key].name : this.state.invitedEmails[key].email)
                            : "")
                        }

                        onChange={(e, newValue) => {
                            console.log("Autocomplete:", newValue);
                            this.updateEmail(key, newValue)
                        }}

                        renderInput={(params) => 
                            <TextField {...params} placeholder="Name" variant="standard" className={/*"form-control" + */(this.state.invitedEmails[key].invalidEmail ? " is-invalid" : "")} />
                        }
                    />
                </div>
            </div>
            <div className="col-lg-3 p-0 pl-lg-4">
                <div className="custom-control custom-radio pt-4" onClick={(e) => this.toggleNewUserIsManager(e, key)}>
                    <input className="custom-control-input" type="radio" id={"new-user-raido"+key} checked={this.state.invitedEmails[key].is_site_manager} onClick={(e) => this.toggleNewUserIsManager(e, key)} />
                    <label className="custom-control-label fw-200" for={"new-user-raido"+key}>
                        {this.state.invitedEmails[key].is_site_manager ? "Primary Contact" : "Set as Primary"}
                    </label>
                </div>
            </div>
        </React.Fragment>)
    }

    renderOptions = () => {
        return this.state.positions.map(r => {
            //console.log("Calling renderOptions with option " + r);
            return <option value={r} key={r}>{r}</option>
        })
    }

}
